<template>
  <section class="all-cases-view">
    <div class="navbarComponent">
      <div class="container-default">
        <div class="listings-navbar">
          <el-row gutter="20">
            <el-col :lg="14" :xl="14">
              <ul class="d-flex list-items-group-15 barcode-print">
                <li>
                  <div
                    class="form-element input-with-icon search-input-lg w-200"
                  >
                    <el-input
                      placeholder="Accession / Patient Name"
                      v-model="search_string"
                      @input="getAutoCompleteCases()"
                      :clearable="true"
                    ></el-input>
                    <span>
                      <img
                        src="@/assets/images/icons/search-icon-with-bg-red.svg"
                        alt="icon"
                        class="img-fluid icon"
                      />
                    </span>
                  </div>
                </li>
              </ul>
            </el-col>
            <el-col :lg="10" :xl="10">
              <ul class="d-flex justify-content-end list-items-group-15 w-90">
                <li>
                  <div
                    class="form-element search-with-filter-input-without-icon"
                  >
                    <el-select
                      v-model="searchBy"
                      clearable
                      filterable
                      default-first-option
                      :reserve-keyword="false"
                      placeholder="Select Search"
                      @change="clearInputSearch"
                      @clear="clearSearchBy"
                      @focus="initializeSearchOptions"
                    >
                      <el-option
                        v-for="item in searchOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </div>
                </li>

                <li v-if="searchBy == 'hospital'">
                  <div class="form-element search-with-filter-input">
                    <el-select
                      v-model="hospital"
                      placeholder="Select Ordering Facility"
                      default-first-option
                      :reserve-keyword="false"
                      filterable
                      clearable
                      @change="handleCurrentChange(1)"
                      @focus="fetchAllHospitals"
                      :loading="hospitalLoading"
                    >
                      <el-option
                        v-for="hospital in hospitalsData.data"
                        :key="hospital"
                        :label="hospital.name"
                        :value="hospital.name"
                      >
                      </el-option>
                    </el-select>
                    <span class="search-icon">
                      <img
                        src="@/assets/images/icons/search-icon-with-bg-red.svg"
                        alt="icon"
                        class="img-fluid"
                      />
                    </span>
                  </div>
                </li>
                <li v-else-if="searchBy == 'ordering_physician'">
                  <div class="form-element search-with-filter-input">
                    <el-select
                      v-model="physician"
                      placeholder="Select"
                      default-first-option
                      :reserve-keyword="false"
                      filterable
                      clearable
                      @change="handleCurrentChange(1)"
                      :loading="physicianLoading"
                      remote
                      :remote-method="remoteMethodPhysician"
                    >
                      <el-option
                        v-for="physician in physiciansData.data"
                        :key="physician"
                        :label="physician.name"
                        :value="physician.name"
                      >
                      </el-option>
                    </el-select>
                    <span class="search-icon">
                      <img
                        src="@/assets/images/icons/search-icon-with-bg-red.svg"
                        alt="icon"
                        class="img-fluid"
                      />
                    </span>
                  </div>
                </li>
                <li v-else-if="searchBy == 'case_types'">
                  <div class="form-element search-with-filter-input">
                    <el-select
                      v-model="caseType"
                      placeholder="Select"
                      filterable
                      clearable
                      default-first-option
                      @clear="clearTest"
                      :reserve-keyword="false"
                      @change="handleCurrentChange(1)"
                    >
                      <el-option
                        v-for="caseType in getLabBasedCaseTypes(caseTypes)"
                        :key="caseType"
                        :label="caseType.label"
                        :value="caseType.value"
                      >
                      </el-option>
                    </el-select>
                    <span class="search-icon">
                      <img
                        src="@/assets/images/icons/search-icon-with-bg-red.svg"
                        alt="icon"
                        class="img-fluid"
                      />
                    </span>
                  </div>
                </li>
                <li v-else-if="searchBy == 'Patient DOB'">
                  <div class="search-with-date-input">
                    <el-date-picker
                      format="MM-DD-YYYY"
                      v-model="date_of_birth"
                      :disabled-date="disabledDate"
                      @change="fetchAllPhlebioOrders()"
                      placeholder="Select DOB"
                      :clearable="true"
                    ></el-date-picker>
                  </div>
                </li>
              </ul>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="container-default" style="padding-top: 0;">
      <div class="listings-navbar">
        <el-row gutter="20">
          <el-col :lg="12" :xl="12"> </el-col>
        </el-row>
      </div>
      <div class="primary-content-view-new" v-loading="loading">
        <div
          class="listings listings__table listings__all-cases"
          id="all-cases-listings"
        >
          <el-table
            :data="getAllPhlebioOrders.data"
            height="calc(100vh - 200px)"
            :default-sort="{
              prop: orderBy,
              order: orderType == 'asc' ? 'ascending' : 'descending',
            }"
            @sort-change="onChangeSort"
          >
            <el-table-column label="Phlebio Order Id" width="160">
              <template #default="scope">
                <span
                  v-if="scope.row.Phlebio_Order_id"
                  class="ordering_physician-value"
                  >{{ scope.row.Phlebio_Order_id }}</span
                >
                <span v-else>--</span>
              </template>
            </el-table-column>
            <el-table-column
              label="Case Type"
              prop="case_types"
              min-width="135px"
            >
              <template #default="scope">
                <div
                  class="case-type"
                  v-if="scope.row.case_types && scope.row.case_types.length > 1"
                >
                  <el-dropdown>
                    <span class="case-data-type">
                      {{ getCaseTypes(scope.row.case_types)[0] }}

                      <img
                        src="@/assets/images/case-type-arrow.svg"
                        alt="icon"
                      />
                    </span>

                    <template #dropdown>
                      <el-dropdown-menu class="case-type-dropdown">
                        <el-dropdown-item
                          class="cases-view"
                          v-for="(caseType, index) of getCaseTypes(
                            scope.row.case_types
                          )"
                          :key="index"
                        >
                          {{ caseType }}
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </div>
                <div
                  v-else-if="
                    scope.row.case_types && scope.row.case_types.length == 1
                  "
                  class="case-type"
                >
                  <span class="case-data-type">
                    {{ getCaseTypes(scope.row.case_types)[0] }}
                  </span>
                </div>
                <div v-else></div>
              </template>
            </el-table-column>

            <el-table-column label="Patient Name" width="170">
              <template #default="scope">
                <div class="name-accession">
                  <span class="text-center ordering_facility-value">
                    {{
                      (scope.row.Patient_first_name || "--") +
                      " " +
                      (scope.row.Patient_last_name || "")
                    }}</span
                  >
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Patient DOB" min-width="100">
              <template #default="scope">
                {{ formatDate(scope.row.Patient_dob) }}
              </template>
            </el-table-column>
            <el-table-column label="Ordering Facilities" width="170">
              <template #default="scope">
                <el-button
                  placement="top"
                  class="title-on-hover"
                  :title="scope.row.Client_name ? scope.row.Client_name : '--'"
                >
                  <span
                    class="text-center ordering_facility-value"
                    v-if="scope.row.Client_name"
                    >{{ scope.row.Client_name }}</span
                  >
                  <span v-else>--</span>
                </el-button>
              </template>
            </el-table-column>
            <el-table-column label="Physician" min-width="120">
              <template #default="scope">
                <el-button
                  placement="top"
                  class="title-on-hover"
                  :title="
                    (scope.row.Provider_first_name || '--') +
                    ' ' +
                    (scope.row.Provider_middle_name || '') +
                    ' ' +
                    (scope.row.Provider_last_name || '')
                  "
                >
                  <span class="ordering_physician-value">
                    {{
                      (scope.row.Provider_first_name || "--") +
                      " " +
                      (scope.row.Provider_middle_name || "") +
                      " " +
                      (scope.row.Provider_last_name || "")
                    }}
                  </span>
                </el-button>
              </template>
            </el-table-column>

            <el-table-column label="Created ON" width="125">
              <template #default="scope">
                <div v-if="scope.row.created_at" class="d-flex">
                  <div class="date pr-10">
                    {{ momentWithTimezone(scope.row.created_at, "MM-DD-yyyy") }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Status" width="130">
              <template #default="scope">
                <div class="column-status">
                  <span v-if="scope.row.Status" class="el-dropdown-link">
                    <el-tag :type="getStatusType(scope.row.Status)"
                      >{{ scope.row.Status }}
                    </el-tag>
                  </span>
                  <span v-else>--</span>
                </div>
              </template>
            </el-table-column>

            <el-table-column
              prop="accession_id"
              label="ACCESSION ID"
              min-width="150"
            >
              <template #default="scope">
                <div class="name-accession">
                  <span
                    id="accession-id"
                    @click="goToCaseView(scope.row.case_id)"
                  >
                    {{ scope.row.accession_id }}
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="action"
              label="Actions"
              width="100"
              fixed="right"
            >
              <template #default="scope">
                <ul class="listings__action-buttons">
                  <li @click="viewPhlebioOrder(scope.row._id)">
                    <el-button class="view-button" title="View Phlebio">
                      <img
                        class="action-active"
                        src="@/assets/images/icons/actions/view-icon.svg"
                        alt="icon"
                      />
                      <img
                        class="action-inactive"
                        src="@/assets/images/icons/actions/view-inactive.svg"
                        alt="icon"
                      />
                    </el-button>
                  </li>
                  <li
                    v-if="
                      hasPermission('orders', 'create_accession_new') &&
                      scope.row.is_phlebo_order &&
                      !scope.row.accession_id && 
                      (scope.row.Status == 'Completed' || 
                      scope.row.Status == 'Performed'||
                      scope.row.Status == 'Delivered to Lab' ||
                      scope.row.Status == 'Deliverd to Lab')
                    "
                  >
                    <el-button
                      @click="convertToCase(scope.row)"
                      class="approval-button"
                      title="Convert To Case"
                    >
                      <img
                        src="@/assets/images/icons/actions/convert-to-case-inactive.svg"
                        alt="icon"
                        class="action-inactive"
                      />
                      <img
                        src="@/assets/images/icons/actions/convert-to-case-active.svg"
                        class="action-active"
                        alt="icon"
                      />
                    </el-button>
                  </li>
                </ul>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="listings listings__pagination">
          <el-pagination
            v-model:currentPage="page"
            v-model:page-size="pageSize"
            :page-sizes="[25, 50, 75, 100]"
            layout=" prev, pager, next, jumper, sizes ,total"
            :total="getAllPhlebioOrders.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import UserPermissionsHelper from "@/mixins/UserPermissionsHelper";
import caseConstants from "@/config/constants/caseConstants";
import { phlebioSearchOptions } from "@/config/constants/searchConstants";
import {
  successNotification,
  errorNotification,
} from "@/helpers/notifications";
import caseDataHelper from "@/mixins/caseDataHelper";
import FilterHelper from "@/mixins/FilterHelper";
import moment from "moment";

export default {
  mixins: [UserPermissionsHelper, caseDataHelper, FilterHelper],
  data() {
    return {
      page: 1,
      pageSize: 25,
      loading: false,
      caseTypeMap: caseConstants.CASE_TYPE_MAP,
      search_string: "",
      searchBy: "",
      hospitalsData: {},
      physiciansData: {},
      hospitalLoading: false,
      physicianLoading: false,
      searchOptions: phlebioSearchOptions,
      hospital: "",
      physician: "",
      caseTypes: caseConstants.CASE_TYPES_DATA,
      caseType: "",
      date_of_birth: "",
      disabledDate: (time) => {
        return time.getTime() > Date.now();
      },
    };
  },
  props: {
    isPhlebioOrders: {
      default: false,
    },
    isPhlebioRequests: {
      default: false,
    },
  },
  computed: {
    ...mapGetters("phlebio", [
      "getAllPhlebioOrders",
      "getCaseConversionStatus",
      "getCaseData",
    ]),
    ...mapGetters("errors", ["getErrors", "getError"]),
    ...mapGetters("hospitals", ["getAllHospitals"]),
    ...mapGetters("physicians", ["getAllPhysicians"]),
    getCaseTypes() {
      return (caseTypes) => {
        return (caseTypes || []).map((e) => this.caseTypeMap[e]);
      };
    },
    getStatusType() {
      return (statusVal) => {
        const status = statusVal.trim();
        if (status == "ACTIVE") {
          return "";
        } else if (status == "CREATED") {
          return "success";
        } else if (status == "TEST_RESULTS") {
          return "info";
        } else if (status == "pending") {
          return "danger";
        } else {
          return "";
        }
      };
    },
  },
  async mounted() {
    this.setQueryParamsData();

    await this.fetchAllPhlebioOrders();
  },
  methods: {
    setQueryParamsData() {
      this.page = this.$route.query.selectedPage
        ? parseInt(this.$route.query.selectedPage)
        : 1;
      this.pageSize = this.$route.query.selectedPageSize
        ? parseInt(this.$route.query.selectedPageSize)
        : 25;
      if (this.$route.query.selectedString) {
        this.search_string = this.$route.query.selectedString;
      }

      if (this.$route.query.selectedCase) {
        this.caseType = this.$route.query.selectedCase;
        this.fetchAllPhlebioOrders();
      }

      if (this.$route.query.selectedPhysician) {
        const savedQuery = localStorage.getItem("searchQueryPhysician");
        if (savedQuery && this.$route.query.selectedString !== savedQuery) {
          this.fetchAllPhysicians(savedQuery);
        }
        this.physician = this.$route.query.selectedPhysician;
      }
      if (this.$route.query.selectedHospital) {
        this.fetchAllHospitals();
        this.hospital = this.$route.query.selectedHospital;
      }
      if (this.$route.query.selectedDateOfBirth) {
        this.date_of_birth = this.$route.query.selectedDateOfBirth;
      }
      this.searchBy = this.$route.query.selectedSearchBy;
    },
    initializeSearchOptions() {
      this.searchOptions = [...this.searchOptions];
    },
    clearInputSearch() {
      if (
        this.hospital ||
        this.physician ||
        this.caseType ||
        this.search_string ||
        this.date_of_birth
      ) {
        this.hospital = "";
        this.physician = "";
        this.caseType = "";
        this.search_string = "";
        this.date_of_birth = "";
        this.page = 1;
        this.fetchAllPhlebioOrders(1);
      }
    },
    async remoteMethodPhysician(query) {
      if (query !== "") {
        localStorage.setItem("searchQueryPhysician", query);
        await this.fetchAllPhysicians(query);
      }
    },
    async fetchAllPhysicians(physician) {
      this.physicianLoading = true;
      let params = {
        limit: 25,
      };
      if (physician) {
        params.search_string = physician;
      }
      await this.$store.dispatch("physicians/fetchAllPhysicians", params);
      this.physiciansData = JSON.parse(JSON.stringify(this.getAllPhysicians));
      this.physicianLoading = false;
    },
    async fetchAllHospitals() {
      this.hospitalLoading = true;

      await this.$store.dispatch("hospitals/fetchAllHospitals", {
        get_all: true,
      });
      this.hospitalsData = JSON.parse(JSON.stringify(this.getAllHospitals));
      this.hospitalLoading = false;
    },
    goToCaseView(case_id) {
      this.$router.push({
        name: "CaseView",
        params: { case_id: case_id },
        query: { ...this.$route.query, redirectRoute: this.$route.name },
      });
    },
    viewPhlebioOrder(phleboId) {
      this.$router.push({
        name: "PhlebioOrderDetails",
        params: {
          phlebio_id: phleboId,
        },
        query: { ...this.$route.query, redirectRoute: this.$route.name },
      });
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.page = 1;

      this.fetchAllPhlebioOrders();
    },
    handleCurrentChange(page) {
      this.page = page;

      this.fetchAllPhlebioOrders();
    },
    prepareParams() {
      const params = {
        page: this.page,
        limit: this.pageSize,
      };
      if (this.isPhlebioOrders) {
        params.phlebo_emr_order = true;
      }
      if (this.isPhlebioRequests) {
        params.is_phlebo_order = true;
      }
      if (this.search_string) {
        params.search_string = this.search_string;
      }

      if (this.caseType) {
        params.case_type = this.caseType;
      }
      if (this.hospital) {
        params.Client_name = this.hospital;
      }
      if (this.physician) {
        // Remove "Dr" from the physician name if it exists
        params.Provider_name = this.physician.replace(/^Dr\s+/, '');
      }
      if (this.date_of_birth) {
        const date_of_birth = moment(this.date_of_birth).format("YYYY-MM-DD");

        params.Patient_dob = date_of_birth;
      }
      return params;
    },
    getAutoCompleteCases() {
      setTimeout(() => {
        this.page = 1;
        this.fetchAllPhlebioOrders();
      }, 600);
    },
    async fetchAllPhlebioOrders() {
      try {
        this.loading = true;
        let params = this.prepareParams();

        await this.$store.dispatch("phlebio/fetchPhlebioOrders", params);
        this.updateQueryParams(params);

        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    updateQueryParams(params) {
      let selectedParams = {
        selectedPage: params.page,
        selectedCase: params.case_type,
        selectedHospital: params.hospital,
        selectedPhysician: params.ordering_physician,
        selectedString: params.search_string,
        selectedPageSize: params.limit,
        selectedSearchBy: this.searchBy || undefined,
        selectedDateOfBirth: params.Patient_dob,
      };
      this.updateQueryParam(this.$route.name, selectedParams);
    },
    async convertToCase(order) {
      this.loading = true;

      let params = {
        phlebio_id: order._id,
      };
      await this.$store.dispatch("phlebio/prepareCaseData", params);
      if (this.getCaseData) {
        this.$store.dispatch("phlebio/setPhlebioInfo", this.getCaseData.data);
        this.$router.push({
          name: "Create-Accession",
          params: { phlebio_id: order._id },

          query: { ...this.$route.query, redirectRoute: this.$route.name },
        });
      } else {
        errorNotification(this.getError || "Unable to fetch order data");
      }
      this.loading = false;
    },
  },
};
</script>
